.deviceImageSection {
  .spinner {
    display: flex;
    justify-content: center;
    position: fixed;
    top: 50%;
    left: 25%;
  }

  .predictionsBottom {}

  .deviceImageContainer {
    padding: 0px 25%;
    border-radius: 4px;
    background-color: var(--singleTxn-imageBG);
    img{
      display: block;
    }
  }

  .deviceImageContainer>div {
    width: 100% !important;

    img{
      display: block;
    }
  }

  .imageOverlayCanvas {
    z-index: -1;
  }
}